import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LocalStorageUtils } from 'src/app/utils/localStorage';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const AdminRoutes: RouteInfo[] = [
  { path: '/home-administrador', title: 'Home', icon: 'fas fa-home text-primary', class: 'menu-item-sidebar' },
  { path: '/perfil-de-usuario', title: 'Meu Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-sidebar' }
];

export const AdminRoutesFuncoes: RouteInfo[] = [
  { path: '/usuario/lista-de-usuarios', title: 'Lista Usuários', icon: 'fas fa-list text-primary', class: 'menu-item-sidebar' },
  { path: '/usuario/criar-usuario-cliente', title: 'Novo Usuário', icon: 'fas fa-user-plus text-primary', class: 'menu-item-sidebar' },
  { path: '/usuario/funcao-usuario', title: 'Função Usuário', icon: 'fas fa-user-lock text-primary', class: 'menu-item-sidebar' },
  { path: '/arquivos-clientes', title: 'Arquivos', icon: 'fas fa-file-archive text-primary', class: 'menu-item-sidebar' }
];
export const FuncRoutesFuncoes: RouteInfo[] = [
  { path: '/usuario/lista-de-usuarios-funcionarios', title: 'Lista Usuários', icon: 'fas fa-list text-primary', class: 'menu-item-sidebar' },
  { path: '/usuario/criar-usuario-cliente', title: 'Novo Usuário', icon: 'fas fa-user-plus text-primary', class: 'menu-item-sidebar' },
  { path: '/usuario/funcao-usuario-funcionario', title: 'Função Usuário', icon: 'fas fa-user-lock text-primary', class: 'menu-item-sidebar' },
  { path: '/arquivos-clientes', title: 'Arquivos', icon: 'fas fa-file-archive text-primary', class: 'menu-item-sidebar' }
];
export const AdminRoutesBlogs: RouteInfo[] = [
  { path: 'blogs/lista-blogs', title: 'Blogs', icon: 'fas fa-folder-open text-primary', class: 'menu-item-sidebar' },
  { path: 'blogs/criar-blog', title: 'Novo Blog', icon: 'fas fa-folder-plus text-primary', class: 'menu-item-sidebar' },
  { path: 'blogs/categorias', title: 'Categorias', icon: 'fas fa-filter text-primary', class: 'menu-item-sidebar' },
  { path: 'blogs/tags', title: 'Tags', icon: 'fas fa-tag text-primary', class: 'menu-item-sidebar' },
];

export const AdminRoutesServicos: RouteInfo[] = [
  { path: 'servico/lista-servicos', title: 'Serviços', icon: 'fas fa-folder-open text-primary', class: 'menu-item-sidebar' },
  { path: 'servico/criar-servico', title: 'Novo Serviço', icon: 'fas fa-folder-plus text-primary', class: 'menu-item-sidebar' },
  { path: 'servico/tags', title: 'Tags', icon: 'fas fa-tag text-primary', class: 'menu-item-sidebar' },
];

export const Usuarios: RouteInfo[] = [
  { path: '/perfil-de-usuario', title: 'Meu Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-sidebar' }
];

export const Clientes: RouteInfo[] = [
  { path: '/home-cliente', title: 'Home', icon: 'fas fa-home text-primary', class: 'menu-item-sidebar' },
  { path: '/perfil-de-usuario', title: 'Meu Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-sidebar' }
];

export const Funcionarios: RouteInfo[] = [
  { path: '/home-funcionario', title: 'Home', icon: 'fas fa-home text-primary', class: 'menu-item-sidebar' },
  { path: '/perfil-de-usuario', title: 'Meu Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-sidebar' }
];

export const Anonimous: RouteInfo[] = [
  { path: '/entrar-na-conta', title: 'Entrar', icon: 'fas fa-sign-in-alt text-primary', class: 'menu-item-drop' },
  { path: '/registrar-usuario', title: 'Criar conta', icon: 'fas fa-user-plus text-primary', class: 'menu-item-drop' }
];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['../../layout/dashboard-layout.component.scss']
})
export class SidebarAdminComponent implements OnInit {


  [x: string]: any;
  token: string = "";
  user: any;
  localStorageUtils = new LocalStorageUtils();
  public menuItems: any[];
  public menuAdminItems: any[];
  public menuFuncItems: any[];
  public menuBlogItems: any[];
  public menuServicoItems: any[];


  constructor(private router: Router) {
  }
  ngOnInit() {

    this.user = this.localStorageUtils.obterUsuario();
    let claim = this.user.claims[5];

    if (this.user != null || this.user != undefined) {
      let claim = this.user.claims[5];

      if (claim.type === 'role' && claim.value === 'Usuário') {
        this.menuItems = Usuarios.filter(menuItem => menuItem);
      }

      if (claim.type === 'role' && claim.value === 'Cliente') {
        this.menuItems = Clientes.filter(menuItem => menuItem);
      }

      if (claim.type === 'role' && claim.value === 'Funcionário') {
        this.menuItems = Funcionarios.filter(menuItem => menuItem);          
        this.menuFuncItems = FuncRoutesFuncoes.filter(menuFuncItem => menuFuncItem);      
        this.menuBlogItems = AdminRoutesBlogs.filter(menuBlogItem => menuBlogItem);
        this.menuServicoItems = AdminRoutesServicos.filter(menuServicoItem => menuServicoItem);
      }

      if (claim.type === 'role' && claim.value === 'Administrador') {
        this.menuItems = AdminRoutes.filter(menuItem => menuItem);
        this.menuAdminItems = AdminRoutesFuncoes.filter(menuAdminItem => menuAdminItem);
        this.menuBlogItems = AdminRoutesBlogs.filter(menuBlogItem => menuBlogItem);
        this.menuServicoItems = AdminRoutesServicos.filter(menuServicoItem => menuServicoItem);
      }
    }
    else {
      this.menuItems = Anonimous.filter(menuItem => menuItem);
    }
  }
  userLogado(): boolean {

    this.token = this.localStorageUtils.obterTokenUsuario();
    this.user = this.localStorageUtils.obterUsuario();

    if (this.user) this.saudacao = 'Olá! ' + this.user.nomeUsuario;

    return this.token !== null;
  }

  logOut() {
    this.localStorageUtils.limparDadosLocaisUsuario();
    this.router.navigate(['/home']);
  }
}

