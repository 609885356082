import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AdminRoutes, AdminRoutesFuncoes, AdminRoutesBlogs, Usuarios, Anonimous, FuncRoutesFuncoes} from '../sidebar/sidebar-admin.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { LocalStorageUtils } from 'src/app/utils/localStorage';

@Component({
  moduleId: module.id,
  selector: 'navbar-admin',
  templateUrl: 'navbar-admin.component.html',
  styleUrls: ['../../layout/dashboard-layout.component.scss']
})

export class NavbarAdminComponent implements OnInit {

  [x: string]: any;

  private listTitles: any[];
  private listTitlesFuncoes: any[];
  private listTitlesBlogs: any[];
  private listTitlesUsuarios: any[];
  private listTitlesFuncionarios: any[];
  private listTitlesAnonimous: any[];
  public location: Location;

  token: string = "";
  user: any;
  localStorageUtils = new LocalStorageUtils();

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(location: Location, private element : ElementRef, private router: Router) {
    this.location = location;    
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = AdminRoutes.filter(listTitle => listTitle);
    this.listTitlesFuncoes = AdminRoutesFuncoes.filter(listTitle => listTitle);
    this.listTitlesFuncionarios = FuncRoutesFuncoes.filter(listTitle => listTitle);
    this.listTitlesBlogs = AdminRoutesBlogs.filter(listTitle => listTitle);
    this.listTitlesUsuarios = Usuarios.filter(listTitle => listTitle);
    this.listTitlesAnonimous = Anonimous.filter(listTitle => listTitle);

    var navbar : HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
   });

  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesFuncoes.length; item++) {
      if (this.listTitlesFuncoes[item].path === titlee) {
        return this.listTitlesFuncoes[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesFuncionarios.length; item++) {
      if (this.listTitlesFuncionarios[item].path === titlee) {
        return this.listTitlesFuncionarios[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesBlogs.length; item++) {
      if (this.listTitlesBlogs[item].path === titlee) {
        return this.listTitlesBlogs[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesUsuarios.length; item++) {
      if (this.listTitlesUsuarios[item].path === titlee) {
        return this.listTitlesUsuarios[item].title;
      }
    }
    for (var item = 0; item < this.listTitlesAnonimous.length; item++) {
      if (this.listTitlesAnonimous[item].path === titlee) {
        return this.listTitlesAnonimous[item].title;
      }
    }
    return 'Dashboard';
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
        this.sidebarOpen();
    } else {
        this.sidebarClose();
    }
  }
  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const html = document.getElementsByTagName('html')[0];
      const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);

      html.classList.add('nav-open');
      if (window.innerWidth < 991) {
        mainPanel.style.position = 'fixed';
      }
      this.sidebarVisible = true;
  };
  sidebarClose() {
      const html = document.getElementsByTagName('html')[0];
      const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
      if (window.innerWidth < 991) {
        setTimeout(function(){
          mainPanel.style.position = '';
        }, 500);
      }
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      html.classList.remove('nav-open');
  };

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }

  userLogado(): boolean {

    this.token = this.localStorageUtils.obterTokenUsuario();
    this.user = this.localStorageUtils.obterUsuario();

    if (this.user)
      this.saudacao = 'Olá! ' + this.user.nomeUsuario;

    return this.token !== null;
  }

  logOut() {
    this.localStorageUtils.limparDadosLocaisUsuario();
    this.router.navigate(['/home']);
  }

}
