import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';

import { LocalStorageUtils } from 'src/app/utils/localStorage';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const Usuarios: RouteInfo[] = [
    // { path: '/entrar-na-conta', title:'Entrar', icon: 'fas fa-sign-in-alt text-primary', class: 'menu-item-drop' },
    //{ path: '/registrar-usuario', title:'Criar conta', icon: 'fas fa-user-plus text-primary', class: 'menu-item-drop' },
    { path: '/perfil-de-usuario', title:'Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-drop' },
];

export const Clientes: RouteInfo[] = [
    // { path: '/entrar-na-conta', title:'Entrar', icon: 'fas fa-sign-in-alt text-primary', class: 'menu-item-drop' },
    //{ path: '/registrar-usuario', title:'Criar conta', icon: 'fas fa-user-plus text-primary', class: 'menu-item-drop' },
    { path: '/perfil-de-usuario', title:'Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-drop' },
    { path: '/home-cliente', title:'Painel', icon: 'fas fa-sliders-h text-primary', class: 'menu-item-drop' }
];

export const Funcionarios: RouteInfo[] = [
    // { path: '/entrar-na-conta', title:'Entrar', icon: 'fas fa-sign-in-alt text-primary', class: 'menu-item-drop' },
    //{ path: '/registrar-usuario', title:'Criar conta', icon: 'fas fa-user-plus text-primary', class: 'menu-item-drop' },
    { path: '/perfil-de-usuario', title:'Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-drop' },
    { path: '/home-funcionario', title:'Painel', icon: 'fas fa-sliders-h text-primary', class: 'menu-item-drop' }
];

export const Administradores: RouteInfo[] = [
    //{ path: '/entrar-na-conta', title:'Entrar', icon: 'fas fa-sign-in-alt text-primary', class: 'menu-item-drop' },
    //{ path: '/registrar-usuario', title:'Criar conta', icon: 'fas fa-user-plus text-primary', class: 'menu-item-drop' },
    { path: '/perfil-de-usuario', title:'Perfil', icon: 'fas fa-user text-primary', class: 'menu-item-drop' },
    { path: '/home-administrador', title:'Painel', icon: 'fas fa-sliders-h text-primary', class: 'menu-item-drop' }
];

export const Anonimos: RouteInfo[] = [
    { path: '/entrar-na-conta', title:'Entrar', icon: 'fas fa-sign-in-alt text-primary', class: 'menu-item-drop' },
    { path: '/registrar-usuario', title:'Criar conta', icon: 'fas fa-user-plus text-primary', class: 'menu-item-drop' }
];
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];

    [x: string]: any;
    token: string = "";
    user: any;
    localStorageUtils = new LocalStorageUtils();
    public menuItems: any[];
    public menuItemsMobs: any[];

    constructor(public location: Location, private router: Router) {
    }

    ngOnInit() {

        this.user = this.localStorageUtils.obterUsuario();

        if (this.user != null || this.user != undefined) {
            let claim = this.user.claims[5];            

            if (claim.type === 'role' && claim.value === 'Usuário') {
                this.menuItems = Usuarios.filter(menuItem => menuItem);
            }
            if (claim.type === 'role' && claim.value === 'Cliente') {
                this.menuItems = Clientes.filter(menuItem => menuItem);
            }
            if (claim.type === 'role' && claim.value === 'Funcionário') {
                this.menuItems = Funcionarios.filter(menuItem => menuItem);
            }

            if (claim.type === 'role' && claim.value === 'Administrador') {
                this.menuItems = Administradores.filter(menuItem => menuItem);
            }            
        }
        else {
            this.menuItems = Anonimos.filter(menuItem => menuItem);
        }
        this.router.events.subscribe((event) => {
            this.isCollapsed = true;
            if (event instanceof NavigationStart) {
                if (event.url != this.lastPoppedUrl)
                    this.yScrollStack.push(window.scrollY);
            } else if (event instanceof NavigationEnd) {
                if (event.url == this.lastPoppedUrl) {
                    this.lastPoppedUrl = undefined;
                    window.scrollTo(0, this.yScrollStack.pop());
                } else
                    window.scrollTo(0, 0);
            }
        });
        this.location.subscribe((ev: PopStateEvent) => {
            this.lastPoppedUrl = ev.url;
        });
    }
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if (titlee === '#/home') {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee === '#/documentation') {
            return true;
        }
        else {
            return false;
        }
    }
    userLogado(): boolean {

        this.token = this.localStorageUtils.obterTokenUsuario();
        this.user = this.localStorageUtils.obterUsuario();

        if (this.user) this.saudacao = this.user.nomeUsuario;

        return this.token !== null;

    }
    logOut() {
        this.localStorageUtils.limparDadosLocaisUsuario();
        window.location.reload();
        this.router.navigate(['/home']);
    }
}
