import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

import { ListaBlog } from '../dashboard/admin/Blog/models/lista-blog';

import { HomeService } from './../services/home.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig]
})

export class HomeComponent implements OnInit {

  showNavigationArrows = false;
  showNavigationIndicators = false;
  imagens: string = environment.imagensBlogUrl;
  public isCollapsed = true;
  formulario: FormGroup;
  listaBlog: ListaBlog[];  
  errorMessage: string;  
  errors: any;
  submitted = false;
  alertState = false;
  public MASKS = MASKS;

  testimonialOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 1 },
      940: { items: 1 }
    },
    nav: false
  }

  logoClientesOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1800,
    navText: ['', ''],
    responsive: {
      0: { items: 2 },
      400: { items: 3 },
      740: { items: 4 },
      940: { items: 5 }
    },
    nav: false
  }

  
  blogsOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1800,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 3 },
      740: { items: 4 },
      940: { items: 4 }
    },
    nav: false
  }

  constructor(
    private fb: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    config: NgbCarouselConfig
  ) {
    config.interval = 3000;
    // config.wrap = false;
    // config.keyboard = false;
    // config.pauseOnHover = false;
  }

  ngOnInit() {

    this.homeService.obterBlogs()
    .subscribe(listaBlog => this.listaBlog = listaBlog, error => this.errorMessage);

    this.formulario = this.fb.group({
      nome: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30)]],
      assunto: ['', [Validators.required,]],
      contato: ['', [<any>Validators.required, <any>NgBrazilValidators.telefone]],
    })

  }

  get f() { return this.formulario.controls; }

  public sendEmail(e: Event) {    
    this.submitted = true;
    this.alertState = false;    
    if (this.formulario.invalid) {
      this.processarFalha;
      return;
    }
    this.submitted = false;    
    this.spinner.show(); 
    emailjs.sendForm('service_2m', 'template_2m_home', e.target as HTMLFormElement, 'user_ww1dWkyMbTG9YrzElTEF3')
    .then((result: EmailJSResponseStatus) => {
      this.processarSucesso(result);
    }, (error) => {
      this.processarFalha(error);
    });
    this.formulario.reset();
  }

  processarSucesso(response: any) {    
    this.submitted = false;    
    this.formulario.reset();
    this.errors = [];

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    let toast = this.toastr.success('Formulário enviado com Sucesso!');
    if (toast) {
      toast.onHidden.subscribe(() => {
        this.router.navigate(['/home']);
      });
    }
  }

  processarFalha(fail: any) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    this.errors = fail.error.errors;
    this.toastr.error('Ocorreu um erro!', 'Opa :(');
  }

}



