import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BlogSidebarComponent } from './sidebar/blog-sidebar.component';
import { FooterComponent } from '../../navegacao-site/components/footer/footer.component';
import { NavbarComponent } from '../../navegacao-site/components/navbar/navbar.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  declarations: [    

    BlogSidebarComponent
  ],
  exports: [
    BlogSidebarComponent
  ]
})
export class ComponentsBlogModule { }
