import { UsuariosService } from 'src/app/conta/services/usuarios.service';
import { Component, OnInit, Inject, Renderer2, ElementRef, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 0;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    title = '2M - Escritório de Contabilidade e Acessoria Contábil.';    

    private _router: Subscription;

    constructor(private renderer: Renderer2,
        private router: Router, @Inject(DOCUMENT,)
        private document: any,
        private element: ElementRef,
        public location: Location,
        private usuariosService: UsuariosService,
        private titleService: Title,
        private meta: Meta) { }

    @HostListener('window:scroll', ['$event'])

    hasScrolled() {

        var st = window.pageYOffset;
        // Make sure they scroll more than delta
        if (Math.abs(lastScrollTop - st) <= delta)
            return;

        var navbar = document.getElementsByTagName('nav')[0];

        // If they scrolled down and are past the navbar, add class .headroom--unpinned.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            if (navbar.classList.contains('headroom--pinned')) {
                navbar.classList.remove('headroom--pinned');
                navbar.classList.add('headroom--unpinned');
            }
            // $('.navbar.headroom--pinned').removeClass('headroom--pinned').addClass('headroom--unpinned');
        } else {
            // Scroll Up
            //  $(window).height()
            if (st + window.innerHeight < document.body.scrollHeight) {
                // $('.navbar.headroom--unpinned').removeClass('headroom--unpinned').addClass('headroom--pinned');
                if (navbar.classList.contains('headroom--unpinned')) {
                    navbar.classList.remove('headroom--unpinned');
                    navbar.classList.add('headroom--pinned');
                }
            }
        }

        lastScrollTop = st;
    };
    ngOnInit() {

        this.titleService.setTitle(this.title);
        this.meta.addTag({name: '', content: ''});
        this.meta.updateTag({name: 'Keywords', content: 'escritório de contabilidade, escrituração fiscal, abertura de empresas, acessoria fiscal, planejamento tributário, auditoria, obrigações acessórias '});
        this.meta.updateTag({name: 'description', content: 'A 2M Contabilidade é especilaista em contabilidade para varejo. Contabilidade para Comércio. Contabildiade para Serviços. Contabildiade para área de Saúde. Redução de Carga Tributária'});
         
        this.usuariosService.TokenExpired();

        var navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            } else {
                window.document.activeElement.scrollTop = 0;
            }

        });
        this.hasScrolled();
    }
}
