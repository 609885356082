import { TagsBlog } from './../sub-pages/blog/models/tags-blog';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { ListaBlog } from "../dashboard/admin/Blog/models/lista-blog";
import { CategoriaBlog } from "../sub-pages/blog/models/categoria";
import { TagsServico } from "../sub-pages/servicos/models/tags-servico";
import { ListaServico } from './../dashboard/admin/servicos/models/lista-servico';

import { BaseService } from "./base.service";

@Injectable({
    providedIn: 'root'
})
export class HomeService extends BaseService {
    private blogUrl: string = '/blogs/';
    private servicoUrl: string = '/servicos/';
    private servicoTagsUrl: string = '/servicoTags/';
    private blogTagsUrl: string = '/blogTags/';
    private categoriaUrl: string = '/categorias/';
    constructor(private http: HttpClient) { super() }


    //BLOGS

    obterBlogPorSlug(slugBlog: string): Observable<ListaBlog> {
        return this.http
            .get<ListaBlog>(this.UrlService + this.blogUrl + 'slug/' + slugBlog)
            .pipe(catchError(super.serviceError));
    }

    obterBlogPorCategoria(categoria: string): Observable<ListaBlog> {
        return this.http
            .get<ListaBlog>(this.UrlService + this.blogUrl + 'categoriaSlug/' + categoria)
            .pipe(catchError(super.serviceError));
    }

    obterBlogs(): Observable<ListaBlog[]> {
        return this.http
            .get<ListaBlog[]>(this.UrlService + this.blogUrl)
            .pipe(catchError(super.serviceError));
    }

    obterCategorias(): Observable<CategoriaBlog[]> {
        return this.http
            .get<CategoriaBlog[]>(this.UrlService + this.categoriaUrl)
            .pipe(catchError(super.serviceError));
    }

    obterTagsBlog(): Observable<TagsBlog[]> {
        return this.http
            .get<TagsBlog[]>(this.UrlService + this.blogTagsUrl)
            .pipe(catchError(super.serviceError));
    }

    obterBlogPorTag(tagBlog: string): Observable<ListaBlog> {
        return this.http
            .get<ListaBlog>(this.UrlService + this.blogUrl + 'tag/' + tagBlog)
            .pipe(catchError(super.serviceError));
    }

    // SERVIÇOS

    obterServicos(): Observable<ListaServico> {
        return this.http
            .get<ListaServico>(this.UrlService + this.servicoUrl)
            .pipe(catchError(super.serviceError));
    }

    obterServicoPorSlug(slugServico: string): Observable<ListaServico> {
        return this.http
            .get<ListaServico>(this.UrlService + this.servicoUrl + 'slug/' + slugServico)
            .pipe(catchError(super.serviceError));
    }

    obterServicoPorTag(tagServico: string): Observable<ListaServico> {
        return this.http
            .get<ListaServico>(this.UrlService + this.servicoUrl + 'tag/' + tagServico)
            .pipe(catchError(super.serviceError));
    }
    
    obterTagsServico(): Observable<TagsServico[]> {
        return this.http
            .get<TagsServico[]>(this.UrlService + this.servicoTagsUrl)
            .pipe(catchError(super.serviceError));
    }

}