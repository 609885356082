

import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { WebsiteLayoutComponent } from './navegacao/navegacao-site/layout/website-layout.component';
import { UserLayoutComponent } from './navegacao/navegacao-user/layout/user-layout.component';
import { DashboardLayoutComponent } from './navegacao/navegacao-dashboard/layout/dashboard-layout.component';
import { ServicoLayoutComponent } from './navegacao/navegacao-servico/layout/servico-layout.component';
import { BlogLayoutComponent } from './navegacao/navegacao-blog/layout/blog-layout.component';

import { HomeComponent } from './home/home.component';
import { AcessoNaoAutorizadoComponent } from './sub-pages/acesso-nao-autorizado/acesso-nao-autorizado.component';
import { PaginaNaoEncontradaComponent } from './sub-pages/pagina-nao-encontrada/pagina-nao-encontrada.component';

const routes: Routes =[    
    { path: '', redirectTo: '/home', pathMatch: 'full' },   

    { path: '', component: WebsiteLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-site/layout/website-layout.module').then(m => m.WebsiteLayoutModule) }] },
    { path: '', component: ServicoLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-servico/layout/servico-layout.module').then(m => m.ServicoLayoutModule) }] },
    { path: '', component: BlogLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-blog/layout/blog-layout.module').then(m => m.BlogLayoutModule) }] },
    { path: '', component: DashboardLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-dashboard/layout/dashboard-layout.module').then(m => m.DashboardLayoutModule) }], canLoad:[] },
    { path: '', component: UserLayoutComponent, children: [{ path: '', loadChildren: () => import('./navegacao/navegacao-user/layout/user-layout.module').then(m => m.UserLayoutModule) }] },
    
    { path: 'home', component: HomeComponent }, 
    { path: 'acesso-nao-autorizado', component: AcessoNaoAutorizadoComponent }, 
    { path: 'pagina-nao-encontrada', component: PaginaNaoEncontradaComponent }, 
    { path: '**', component: PaginaNaoEncontradaComponent }, 
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,{ useHash: false })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
