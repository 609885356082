import { AppRoutingModule } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { ToastrModule } from 'ngx-toastr';
import { NgBrazil } from 'ng-brazil';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CustomFormsModule } from 'ng2-validation';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { WebsiteLayoutComponent } from './navegacao/navegacao-site/layout/website-layout.component';
import { DashboardLayoutComponent } from './navegacao/navegacao-dashboard/layout/dashboard-layout.component';
import { UserLayoutComponent } from './navegacao/navegacao-user/layout/user-layout.component';
import { ServicoLayoutComponent } from './navegacao/navegacao-servico/layout/servico-layout.component';
import { BlogLayoutComponent } from './navegacao/navegacao-blog/layout/blog-layout.component';

import { ComponentsUserModule } from './navegacao/navegacao-user/components/component-user.module';
import { ComponentsWebsiteModule } from './navegacao/navegacao-site/components/components-website.module';
import { ComponentsDashboardModule } from './navegacao/navegacao-dashboard/components/components-dashboard.module';
import { ComponentsServicoModule } from './navegacao/navegacao-servico/components/components-servico.module';
import { ComponentsBlogModule } from './navegacao/navegacao-blog/components/components-blog.module';

import { environment } from 'src/environments/environment';

import { UsuariosService } from './conta/services/usuarios.service';
import { ErrorInterceptor } from './services/error.handle.service';
import { PaginaNaoEncontradaComponent } from './sub-pages/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { AcessoNaoAutorizadoComponent } from './sub-pages/acesso-nao-autorizado/acesso-nao-autorizado.component';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];
export function tokenGetter() {
  return localStorage.getItem('app.token');
}

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [    
    AppComponent,
    WebsiteLayoutComponent,
    ServicoLayoutComponent,
    BlogLayoutComponent,
    DashboardLayoutComponent,
    UserLayoutComponent,
    PaginaNaoEncontradaComponent,
    AcessoNaoAutorizadoComponent,
    ConfirmModalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    ComponentsWebsiteModule,
    ComponentsDashboardModule,
    ComponentsServicoModule,
    ComponentsBlogModule,
    ComponentsUserModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      preventDuplicates: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.urlAPI],
        disallowedRoutes: []
      }
    }),
    NgBrazil,
    CustomFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    httpInterceptorProviders, 
    UsuariosService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },    
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
