import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { MASKS, NgBrazilValidators } from 'ng-brazil';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.css']
})
export class FormularioComponent implements OnInit {

  public isCollapsed = true;
  formulario: FormGroup;
  errors: any;
  submitted = false;
  isClosed = false;
  public MASKS = MASKS;
  aceitaNotificacao_result:any;
 
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    
    this.formulario = this.fb.group({
      
      nome: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(60)]],
      email: ['', [Validators.required, Validators.email]],
      telefone: ['', [<any>Validators.required, <any>NgBrazilValidators.telefone]],
      assunto: ['', [Validators.required]],
      mensagem: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(500)]],
      aceitaNotificacao: [''],

    })
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formulario.invalid) {
      return;
    }

    if (this.formulario.value.aceitaNotificacao == false)    
    this.aceitaNotificacao_result = "Não aceito receber notificações"
    else (this.formulario.value.aceitaNotificacao == true)
    this.aceitaNotificacao_result = "Aceito receber notificações";

    this.formulario.value.aceitaNotificacao = this.aceitaNotificacao_result;

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.formulario.value, null, 4));
     
  }

  get f() { return this.formulario.controls; }

  public sendEmail(e: Event) {    
    this.submitted = true;
    this.isClosed = false;    
    if (this.formulario.invalid) {
      this.processarFalha;
      return;
    }

    if (this.formulario.value.aceitaNotificacao == false)    
    this.aceitaNotificacao_result = "Não aceito receber notificações"
    else (this.formulario.value.aceitaNotificacao == true)
    this.aceitaNotificacao_result = "Aceito receber notificações";

    this.formulario.value.aceitaNotificacao = this.aceitaNotificacao_result;

    this.submitted = false;    
    this.spinner.show(); 
    
    emailjs.sendForm('service_2m', 'template_2m_contato', e.target as HTMLFormElement , 'user_zfXKMFGIXWBkJiS6nMMbT')
    .then((result: EmailJSResponseStatus) => {
      this.processarSucesso(result);
    }, (error) => {
      this.processarFalha(error);
    });
    this.formulario.reset();
  }

  processarSucesso(response: any) {    
    this.submitted = false;    
    this.formulario.reset();
    this.errors = [];

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    let toast = this.toastr.success('Formulário enviado com Sucesso!');
    if (toast) {
      toast.onHidden.subscribe(() => {
        this.router.navigate(['/home']);
      });
    }
  }

  processarFalha(fail: any) {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    this.errors = fail.error.errors;
    this.toastr.error('Ocorreu um erro!', 'Opa :(');
  }
}
