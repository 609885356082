import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { LocalStorageUtils } from 'src/app/utils/localStorage';
import { CustomEncoder } from 'src/app/utils/customEncoder';

import { Usuario } from '../models/usuario';
import { ResetarSenha } from '../models/resetarSenha';
import { Registro } from './../models/registro';
import { Login } from './../models/login';
import { RecuperaSenha } from './../models/recuperarSenha';
import { Conta } from './../../dashboard/models/conta';
import { AlterarSenha } from './../../dashboard/models/alterar-senha';
import { AlterarEmail } from './../../dashboard/models/alterar-email';

import { BaseService } from 'src/app/services/base.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class UsuariosService extends BaseService {


  localStorageUtils = new LocalStorageUtils();

  private urlUsuario: string = this.UrlService + '/usuarios/';

  constructor(private http: HttpClient, private _jwtHelper: JwtHelperService, private router: Router) { super(); }


  registrarUsuario(registro: Registro): Observable<any> {
    return this.http.
      post<Usuario>(this.urlUsuario + 'registrarUsuario/', registro, httpOptions)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  logarUsuario(login: Login): Observable<any> {
    return this.http
      .post<Usuario>(this.urlUsuario + 'logarUsuario/', login, httpOptions)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  recuperaSenha(recuperaSenha: RecuperaSenha): Observable<any> {
    return this.http
      .post(this.urlUsuario + 'recuperaSenha/', recuperaSenha, httpOptions)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  novaSenha(resetarSenha: ResetarSenha): Observable<any> {
    return this.http
      .post(this.urlUsuario + 'novaSenha/', resetarSenha, httpOptions)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmarEmail(token: string, email: string): Observable<any> {
    let param = new HttpParams({ encoder: new CustomEncoder() });
    param = param.append('token', token);
    param = param.append('email', email);

    return this.http.get(this.urlUsuario + 'confirmarEmail/', { params: param })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  confirmarAlteracaoEmail(token: string, email: string, usuarioId): Observable<any> {
    let param = new HttpParams({ encoder: new CustomEncoder() });
    param = param.append('token', token);
    param = param.append('email', email);
    param = param.append('usuarioId', usuarioId);

    return this.http.get(this.urlUsuario + 'confirmarAlteracaoEmail/', { params: param })
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterUsuarioPorId(id: string): Observable<Usuario> {
    return this.http
      .get<Usuario>(this.urlUsuario + 'obterUsuario/' + id)
      .pipe(catchError(super.serviceError));
  }

  atualizarUsuario(usuarios: Usuario) {
    return this.http.put(this.urlUsuario + 'atualizarUsuario/' + usuarios.id, usuarios)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  excluirUsuario(id: string): Observable<Usuario> {
    return this.http.delete(this.urlUsuario + id)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  atualizarSenha(alterarSenha: AlterarSenha) {
    return this.http.post(this.urlUsuario + 'atualizarSenha/', alterarSenha)
    .pipe(map(this.extractData), catchError(this.serviceError));
  }

  atualizarEmail(alterarEmail: AlterarEmail) {
    return this.http.post(this.urlUsuario + 'atualizarEmail/', alterarEmail)
      .pipe(map(this.extractData), catchError(this.serviceError));
  }

  obterTodos(): Observable<Conta> {
    return this.http
      .get<Conta>(this.urlUsuario)
      .pipe(catchError(super.serviceError));
  }

  obterTodosUsuariosClientes(): Observable<Conta> {
    return this.http
      .get<Conta>(this.urlUsuario + 'usuariosClientes')
      .pipe(catchError(super.serviceError));
  }

  persistirUserApp(response: any) {
    this.localStorageUtils.salvarDadosLocaisUsuario(response);
  }

  public TokenExpired() {

    const token = localStorage.getItem('app.token');

    if (token !== null) {
      const expiredToken = this._jwtHelper.isTokenExpired(token);

      if (expiredToken === true) {
        this.localStorageUtils.limparDadosLocaisUsuario();
        this.router.navigate(['/home']);
      }
    }
  }

}
