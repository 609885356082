import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-layout',
  templateUrl: './blog-layout.component.html',
  styleUrls: ['./blog-layout.component.css']
})
export class BlogLayoutComponent implements OnInit {
  
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit(): void {


    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

}
