import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, timeout } from "rxjs/operators";
import { LocalStorageUtils } from "../utils/localStorage";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private spinner: NgxSpinnerService) { }
    localStorageUtil = new LocalStorageUtils();
    token: any;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        this.token = this.localStorageUtil.obterTokenUsuario();       

        // if(req.url.startsWith('https://viacep.com.br/ws/'))
        // {
        //     req = req.clone({ setHeaders: { 'content-type': 'application/json' } });
        //     this.token = null;
        // }

        if (this.token)  req = req.clone({ setHeaders: { 'content-type': 'application/json', 'Authorization': `Bearer ${this.token}` } });        

        return next.handle(req).pipe(finalize(() => {
                 setTimeout(() => {
                    this.spinner.hide();
                }, 1000);
        }),
            catchError(error => {

                if (error instanceof HttpErrorResponse) {

                    if (error.status === 401) {
                        this.localStorageUtil.limparDadosLocaisUsuario();
                        this.router.navigate(['/entrar-na-conta'], { queryParams: { returnUrl: this.router.url } });
                    }
                    if (error.status === 403) this.router.navigate(['/acesso-nao-autorizado']);

                    if (error.status === 404) this.router.navigate(['/pagina-nao-encontrada']);
                }

                return throwError(error);
            })
        );
    }

}