import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TextMaskModule } from 'angular2-text-mask';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FormularioComponent } from './formulario/formulario.component';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,    
    NgxSpinnerModule,    
    TextMaskModule,
    ToastrModule.forRoot(),
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    FormularioComponent
  ],
  exports: [
    FooterComponent,    
    NavbarComponent,    
    FormularioComponent
  ]
})
export class ComponentsWebsiteModule { }
