import { Component, OnInit } from '@angular/core';
import { ListaServico } from 'src/app/dashboard/admin/servicos/models/lista-servico';
import { HomeService } from 'src/app/services/home.service';
import { TagsServico } from 'src/app/sub-pages/servicos/models/tags-servico';

@Component({
  selector: 'servico-sidebar',
  templateUrl: './servico-sidebar.component.html',
  styleUrls: ['./servico-sidebar.component.css']
})
export class ServicoSidebarComponent implements OnInit {
  listaServicos: ListaServico; 
  tagsServico: TagsServico[];
  errorMessage: string;  
  constructor(
    private homeService: HomeService
    ) { }

  ngOnInit(): void {
    this.homeService.obterTagsServico()
    .subscribe(tagsServico => this.tagsServico = tagsServico, error => this.errorMessage);

    this.homeService.obterServicos()
    .subscribe(listaServicos => this.listaServicos = listaServicos, error => this.errorMessage);
  }

}
