import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-servico-layout',
  templateUrl: './servico-layout.component.html',
  styleUrls: ['./servico-layout.component.css']
})
export class ServicoLayoutComponent implements OnInit {
  
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit(): void {


    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

}
