import { Component, OnInit } from '@angular/core';

import { CategoriaBlog } from 'src/app/sub-pages/blog/models/categoria';
import { TagsBlog } from 'src/app/sub-pages/blog/models/tags-blog';
import { ListaBlog } from 'src/app/dashboard/admin/Blog/models/lista-blog';

import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'blog-sidebar',
  templateUrl: './blog-sidebar.component.html',
  styleUrls: ['./blog-sidebar.component.css']
})
export class BlogSidebarComponent implements OnInit {
  categoriaBlog: CategoriaBlog[]; 
  tagsBlog: TagsBlog[];
  listaBlogs: ListaBlog[]; 
  errorMessage: string;  
  constructor(
    private homeService: HomeService,
    ) { }

  ngOnInit(): void {
    this.homeService.obterCategorias()
    .subscribe(categoriaBlog => this.categoriaBlog = categoriaBlog, error => this.errorMessage);

    this.homeService.obterTagsBlog()
    .subscribe(tagsBlog =>  this.tagsBlog = tagsBlog, error => this.errorMessage);

    this.homeService.obterBlogs()
    .subscribe(listaBlogs => this.listaBlogs = listaBlogs, error => this.errorMessage);
  }

}
