import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterAdminComponent } from './footer/footer-admin.component';
import { NavbarAdminComponent } from './navbar/navbar-admin.component';
import { SidebarAdminComponent } from './sidebar/sidebar-admin.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  declarations: [
    FooterAdminComponent,
    NavbarAdminComponent,
    SidebarAdminComponent,
  ],
  exports: [
    FooterAdminComponent,    
    NavbarAdminComponent,
    SidebarAdminComponent,

  ]
})
export class ComponentsDashboardModule { }
