// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



// //LOCALSTORAGE(API Local)
// export const environment = {   
//   production: false,
//   imagensBlogUrl: 'http://localhost:4200/assets/img/blog/',
//   imagensServicosUrl: 'http://localhost:4200/assets/img/servico/',  
//   arquivosClientesUrl: 'http://localhost:4200/assets/clientes/',
//   imagensUrlBolsas: 'http://localhost:4200/assets/img/cursos/',
//   imagensUrlI: 'http://localhost:4200/assets/img/logo-instituicao/',
//   urlQI: "http://localhost:4200/assets/img/quem-somos/",
//   urlUi: 'http://localhost:4200/',  
//   urlAPI: 'https://localhost:5001/'
// };

//API SERVIDOR

export const environment = {   
  production: true,
  imagensBlogUrl: 'http://contabilidade2m.com.br/assets/img/blog/',
  imagensServicosUrl: 'http://contabilidade2m.com.br/assets/img/servico/',  
  arquivosClientesUrl: 'http://contabilidade2m.com.br/assets/clientes/',
  imagensUrlBolsas: 'http://contabilidade2m.com.br/assets/img/cursos/',
  imagensUrlI: 'http://contabilidade2m.com.br/assets/img/logo-instituicao/',
 urlQI: "http://contabilidade2m.com.br/assets/img/quem-somos/",
  urlUi: 'http://contabilidade2m.com.br/',
  urlAPI: 'https://contabilidade2m.com.br/api/'
};

//API TESTE

// export const environment = {   
//   production: false,
//   imagensBlogUrl: 'http://2m.dv/assets/img/blog/',
//   imagensServicosUrl: 'http://2m.dv/assets/img/servico/',  
//   arquivosClientesUrl: 'http://2m.dv/assets/clientes/',
//   imagensUrlBolsas: 'http://2m.dv/assets/img/cursos/',
//   imagensUrlI: 'http://2m.dv/assets/img/logo-instituicao/',
//   urlUi: 'http://2m.dv/',
//   urlAPI: 'https://2m.dv/api/'
// };


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.