import { Component } from '@angular/core';

@Component({    
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer-admin.component.html',
    styleUrls: ['../../layout/dashboard-layout.component.scss']
})

export class FooterAdminComponent{
    test : Date = new Date();
}
