import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ListaServico } from 'src/app/dashboard/admin/servicos/models/lista-servico';

import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  listaServicos: ListaServico; 
  errorMessage: string;  
  isShow: boolean;
  topPosToStartShowing = 100;
  data: Date = new Date();

  constructor(
    private router: Router,
    private homeService: HomeService,
    ) { }

  ngOnInit() {

    this.homeService.obterServicos()
    .subscribe(listaServicos => this.listaServicos = listaServicos, error => this.errorMessage);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {

          window.scrollTo(0, pos - 20)

        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);

    });

  }
  getPath() {
    return this.router.url;
  }


  @HostListener('window:scroll')
  checkScroll() {


    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;

    }
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
