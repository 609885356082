import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acesso-nao-autorizado',
  templateUrl: './acesso-nao-autorizado.component.html',
  styleUrls: ['./acesso-nao-autorizado.component.css']
})
export class AcessoNaoAutorizadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
