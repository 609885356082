import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageUtils } from '../utils/localStorage';

export abstract class BaseService {

    urlAPI: string = environment.urlAPI;

    constructor() { }

    protected UrlService: string = this.urlAPI + "api";
    protected LocalStorage = new LocalStorageUtils();

    protected ObterHeaderJson() {
        return { headers: new HttpHeaders({'content-type': 'application/json'})};
    }

    protected ObterAuthHeaderJson() {
        return { headers: new HttpHeaders({'content-type': 'application/json','Authorization': `Bearer ${this.LocalStorage.obterTokenUsuario()}`})};
    }

    protected extractData(response: any) {
        return response.data || {};
    }

    protected extractSingleData(response: any) {
        return response;
    }

    protected extractStringData(response: any) {

        let replacedItem = response.replace('[', '').replace(']', '');

        let replacedResponse = replacedItem.replace('"', '').replace('"', '').replace('"', '').replace('"', '');

        let splitedReponse = replacedResponse.split(',');

        response = splitedReponse;

        return response;
    }

    public obterUsuario() {
        return JSON.parse(localStorage.getItem('app.user'));
    }

    protected obterTokenUsuario(): string {
        return localStorage.getItem('app.token');
    }

    protected serviceError(response: Response | any) {
        let customError: string[] = [];
        let customResponse = { error: { errors: [] } }
        let responseErros: any;

        if (response instanceof HttpErrorResponse) {

            if (response.statusText === "Unknown Error") {
                //customError.push("Ocorreu um erro desconhecido");
                response.error.errors = customError.push("Ocorreu um erro desconhecido");
            }
        }
        if (response.status === 500) {
            customError.push("Ocorreu um erro no processamento, tente novamente mais tarde ou contate o nosso suporte.");
            customResponse.error.errors = customError; // Erros do tipo 500 não possuem uma lista de erros// A lista de erros do HttpErrorResponse é readonly 
            return throwError(customResponse);
        }
        if (response.status === 400) {

            for (const campo in response.error.errors) {
                if (response.error.errors.hasOwnProperty(campo)) {

                    if (response.error.errors.length >= 1) {
                        responseErros = response.error.errors;  

                        for (const erro in responseErros) customError.push(responseErros[erro]);

                        customResponse.error.errors = customError;
                        return throwError(customResponse);
                    }

                    responseErros = response.error.errors[campo];

                    for (const erro in responseErros) customError.push(responseErros[erro]);
                   
                    customResponse.error.errors = customError;
                }
            }
            return throwError(customResponse);
        }

        console.error(response);
        return throwError(response);
    }
}