import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ServicoSidebarComponent } from './sidebar/servico-sidebar.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule
  ],
  declarations: [    

    ServicoSidebarComponent
  ],
  exports: [
    ServicoSidebarComponent
  ]
})
export class ComponentsServicoModule { }
