import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-website-layout',
  templateUrl: './website-layout.component.html',
  styleUrls: ['./website-layout.component.css']
})
export class WebsiteLayoutComponent implements OnInit {
  
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit(): void {


    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }

}
